.typo {
  &__h2, &__h3 {
    font-weight: 500;
  }
}


.typographed-text {
  font-size: 16px;

  p {
    margin-bottom: 16px;
    line-height: 130%;
    letter-spacing: .01rem;
  }
}
