@use './reset';
@use 'material-spirit/src/material-spirit';
@use './typography';
@use './container';
@use 'breakpoint-sass/stylesheets/breakpoint' as bp;


$bp-phone-only: max-width 599px;
$bp-laptop-and-up: 1240px;


body {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  color: #212529;
}

.page-container {

  @include bp.breakpoint($bp-laptop-and-up) {
    display: grid;
    grid-template-columns: min(45%, 700px) minmax(55%, 900);

    .logo-container {
      height: 100vh;
      margin-top: 0;
    }
  }
}

.logo-container {
  padding-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //background-color: #ffecec; - 95
  //background-color: #fffbff; - 99
  background-color: #fff8f7;
}

.logo-img {
  width: 50vw;
  margin-bottom: 1.5rem;
  max-width: 270px;
}

h1.logo-title {
  font-weight: 500;
  line-height: 1.2;
  font-size: 2.5rem;
  margin-bottom: .5rem;
  text-align: center;
}

.project-container {
  @include container.centered-max-width();
}

.project-subtitle {
  font-size: 16px;
  color: #90A4AE;
}

#split-button-img {
  width: 203px;

  @include bp.breakpoint($bp-phone-only) {
    width: 150px;
  }

}
