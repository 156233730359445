@mixin core-styles($breakpoint: null) {
  @if($breakpoint) {
      .mdx-flex-fill--#{$breakpoint} {
        flex: 1 1 auto !important;
      }
  } @else {
    .mdx-flex-fill {
      flex: 1 1 auto !important;
    }
  }
}