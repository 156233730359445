$grow-shrink-types: grow, shrink;
//$grow-shrink-values: 0, 1;

@mixin core-styles($breakpoint: null) {
  @if($breakpoint) {
    @each $type in $grow-shrink-types {
      .mas-flex-#{$type}-#{$breakpoint} {
        flex-#{$type}: 1 !important;
      }

      .mas-flex-#{$type}-0-#{$breakpoint} {
        flex-#{$type}: 0 !important;
      }
    }
  } @else {
    .mas-flex-grow {
      flex-grow: 1 !important;
    }

    .mas-flex-grow-0 {
      flex-grow: 0 !important;
    }

    .mas-flex-shrink {
      flex-shrink: 1 !important;
    }

    .mas-flex-shrink-0 {
      flex-shrink: 0 !important;
    }
  }
}

