$justify-content-options: (
  start: flex-start,
  end: flex-end,
  center: center,
  between: space-between,
  around: space-around,
  evenly: space-evenly
);

@mixin core-styles($breakpoint: null) {
  @if($breakpoint) {
    @each $key, $value in $justify-content-options {
      .mas-justify-#{$key}-#{$breakpoint} {
        justify-content: $value !important;
      }      
    }
  } @else {
    @each $key, $value in $justify-content-options {
      .mas-justify-#{$key} {
        justify-content: $value !important;
      }
    }
  }
}