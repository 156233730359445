body, h1, h2, h3, h4, h5, h6, ul, ol, p, figure, figcaption {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

.mas-items-start {
  align-items: start !important;
}

.mas-items-end {
  align-items: end !important;
}

.mas-items-center {
  align-items: center !important;
}

.mas-items-baseline {
  align-items: baseline !important;
}

.mas-items-stretch {
  align-items: stretch !important;
}

.mas-flex-row {
  flex-direction: row !important;
}

.mas-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.mas-flex-col {
  flex-direction: column !important;
}

.mas-flex-col-reverse {
  flex-direction: column-reverse !important;
}

.mdx-flex-fill {
  flex: auto !important;
}

.mas-flex-grow {
  flex-grow: 1 !important;
}

.mas-flex-grow-0 {
  flex-grow: 0 !important;
}

.mas-flex-shrink {
  flex-shrink: 1 !important;
}

.mas-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.mas-justify-start {
  justify-content: flex-start !important;
}

.mas-justify-end {
  justify-content: flex-end !important;
}

.mas-justify-center {
  justify-content: center !important;
}

.mas-justify-between {
  justify-content: space-between !important;
}

.mas-justify-around {
  justify-content: space-around !important;
}

.mas-justify-evenly {
  justify-content: space-evenly !important;
}

.mas-none {
  display: none !important;
}

.mas-inline {
  display: inline !important;
}

.mas-inline-block {
  display: inline-block !important;
}

.mas-block {
  display: block !important;
}

.mas-grid {
  display: grid !important;
}

.mas-table {
  display: table !important;
}

.mas-table-cell {
  display: table-cell !important;
}

.mas-table-row {
  display: table-row !important;
}

.mas-flex {
  display: flex !important;
}

.mas-inline-flex {
  display: inline-flex !important;
}

.mas-p-0 {
  padding: 0 !important;
}

.mas-p-1 {
  padding: 8px !important;
}

.mas-p-2 {
  padding: 16px !important;
}

.mas-p-3 {
  padding: 24px !important;
}

.mas-p-4 {
  padding: 32px !important;
}

.mas-p-5 {
  padding: 40px !important;
}

.mas-pt-0 {
  padding-top: 0 !important;
}

.mas-pt-1 {
  padding-top: 8px !important;
}

.mas-pt-2 {
  padding-top: 16px !important;
}

.mas-pt-3 {
  padding-top: 24px !important;
}

.mas-pt-4 {
  padding-top: 32px !important;
}

.mas-pt-5 {
  padding-top: 40px !important;
}

.mas-pb-0 {
  padding-bottom: 0 !important;
}

.mas-pb-1 {
  padding-bottom: 8px !important;
}

.mas-pb-2 {
  padding-bottom: 16px !important;
}

.mas-pb-3 {
  padding-bottom: 24px !important;
}

.mas-pb-4 {
  padding-bottom: 32px !important;
}

.mas-pb-5 {
  padding-bottom: 40px !important;
}

.mas-pl-0 {
  padding-left: 0 !important;
}

.mas-pl-1 {
  padding-left: 8px !important;
}

.mas-pl-2 {
  padding-left: 16px !important;
}

.mas-pl-3 {
  padding-left: 24px !important;
}

.mas-pl-4 {
  padding-left: 32px !important;
}

.mas-pl-5 {
  padding-left: 40px !important;
}

.mas-pr-0 {
  padding-right: 0 !important;
}

.mas-pr-1 {
  padding-right: 8px !important;
}

.mas-pr-2 {
  padding-right: 16px !important;
}

.mas-pr-3 {
  padding-right: 24px !important;
}

.mas-pr-4 {
  padding-right: 32px !important;
}

.mas-pr-5 {
  padding-right: 40px !important;
}

.mas-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mas-px-1 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.mas-px-2 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.mas-px-3 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.mas-px-4 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.mas-px-5 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.mas-py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.mas-py-1 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.mas-py-2 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.mas-py-3 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.mas-py-4 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.mas-py-5 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.mas-m-0 {
  margin: 0 !important;
}

.mas-m-1 {
  margin: 8px !important;
}

.mas-m-2 {
  margin: 16px !important;
}

.mas-m-3 {
  margin: 24px !important;
}

.mas-m-4 {
  margin: 32px !important;
}

.mas-m-5 {
  margin: 40px !important;
}

.mas-mt-0 {
  margin-top: 0 !important;
}

.mas-mt-1 {
  margin-top: 8px !important;
}

.mas-mt-2 {
  margin-top: 16px !important;
}

.mas-mt-3 {
  margin-top: 24px !important;
}

.mas-mt-4 {
  margin-top: 32px !important;
}

.mas-mt-5 {
  margin-top: 40px !important;
}

.mas-mb-0 {
  margin-bottom: 0 !important;
}

.mas-mb-1 {
  margin-bottom: 8px !important;
}

.mas-mb-2 {
  margin-bottom: 16px !important;
}

.mas-mb-3 {
  margin-bottom: 24px !important;
}

.mas-mb-4 {
  margin-bottom: 32px !important;
}

.mas-mb-5 {
  margin-bottom: 40px !important;
}

.mas-ml-0 {
  margin-left: 0 !important;
}

.mas-ml-1 {
  margin-left: 8px !important;
}

.mas-ml-2 {
  margin-left: 16px !important;
}

.mas-ml-3 {
  margin-left: 24px !important;
}

.mas-ml-4 {
  margin-left: 32px !important;
}

.mas-ml-5 {
  margin-left: 40px !important;
}

.mas-mr-0 {
  margin-right: 0 !important;
}

.mas-mr-1 {
  margin-right: 8px !important;
}

.mas-mr-2 {
  margin-right: 16px !important;
}

.mas-mr-3 {
  margin-right: 24px !important;
}

.mas-mr-4 {
  margin-right: 32px !important;
}

.mas-mr-5 {
  margin-right: 40px !important;
}

.mas-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mas-mx-1 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.mas-mx-2 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.mas-mx-3 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.mas-mx-4 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.mas-mx-5 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mas-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mas-my-1 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mas-my-2 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.mas-my-3 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.mas-my-4 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.mas-my-5 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mas-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mas-text-left {
  text-align: left !important;
}

.mas-text-center {
  text-align: center !important;
}

.mas-text-right {
  text-align: right !important;
}

.mas-text-justify {
  text-align: justify !important;
}

.mas-w100 {
  width: 100% !important;
}

.mas-max100 {
  max-width: 100% !important;
}

@media (max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape) {
  .mas-items-start-handset {
    align-items: start !important;
  }

  .mas-items-end-handset {
    align-items: end !important;
  }

  .mas-items-center-handset {
    align-items: center !important;
  }

  .mas-items-baseline-handset {
    align-items: baseline !important;
  }

  .mas-items-stretch-handset {
    align-items: stretch !important;
  }

  .mas-flex-row-handset {
    flex-direction: row !important;
  }

  .mas-flex-row-reverse-handset {
    flex-direction: row-reverse !important;
  }

  .mas-flex-col-handset {
    flex-direction: column !important;
  }

  .mas-flex-col-reverse-handset {
    flex-direction: column-reverse !important;
  }

  .mdx-flex-fill--handset {
    flex: auto !important;
  }

  .mas-flex-grow-handset {
    flex-grow: 1 !important;
  }

  .mas-flex-grow-0-handset {
    flex-grow: 0 !important;
  }

  .mas-flex-shrink-handset {
    flex-shrink: 1 !important;
  }

  .mas-flex-shrink-0-handset {
    flex-shrink: 0 !important;
  }

  .mas-justify-start-handset {
    justify-content: flex-start !important;
  }

  .mas-justify-end-handset {
    justify-content: flex-end !important;
  }

  .mas-justify-center-handset {
    justify-content: center !important;
  }

  .mas-justify-between-handset {
    justify-content: space-between !important;
  }

  .mas-justify-around-handset {
    justify-content: space-around !important;
  }

  .mas-justify-evenly-handset {
    justify-content: space-evenly !important;
  }

  .mas-none-handset {
    display: none !important;
  }

  .mas-inline-handset {
    display: inline !important;
  }

  .mas-inline-block-handset {
    display: inline-block !important;
  }

  .mas-block-handset {
    display: block !important;
  }

  .mas-grid-handset {
    display: grid !important;
  }

  .mas-table-handset {
    display: table !important;
  }

  .mas-table-cell-handset {
    display: table-cell !important;
  }

  .mas-table-row-handset {
    display: table-row !important;
  }

  .mas-flex-handset {
    display: flex !important;
  }

  .mas-inline-flex-handset {
    display: inline-flex !important;
  }

  .mas-p-0-handset {
    padding: 0 !important;
  }

  .mas-p-1-handset {
    padding: 8px !important;
  }

  .mas-p-2-handset {
    padding: 16px !important;
  }

  .mas-p-3-handset {
    padding: 24px !important;
  }

  .mas-p-4-handset {
    padding: 32px !important;
  }

  .mas-p-5-handset {
    padding: 40px !important;
  }

  .mas-pt-0-handset {
    padding-top: 0 !important;
  }

  .mas-pt-1-handset {
    padding-top: 8px !important;
  }

  .mas-pt-2-handset {
    padding-top: 16px !important;
  }

  .mas-pt-3-handset {
    padding-top: 24px !important;
  }

  .mas-pt-4-handset {
    padding-top: 32px !important;
  }

  .mas-pt-5-handset {
    padding-top: 40px !important;
  }

  .mas-pb-0-handset {
    padding-bottom: 0 !important;
  }

  .mas-pb-1-handset {
    padding-bottom: 8px !important;
  }

  .mas-pb-2-handset {
    padding-bottom: 16px !important;
  }

  .mas-pb-3-handset {
    padding-bottom: 24px !important;
  }

  .mas-pb-4-handset {
    padding-bottom: 32px !important;
  }

  .mas-pb-5-handset {
    padding-bottom: 40px !important;
  }

  .mas-pl-0-handset {
    padding-left: 0 !important;
  }

  .mas-pl-1-handset {
    padding-left: 8px !important;
  }

  .mas-pl-2-handset {
    padding-left: 16px !important;
  }

  .mas-pl-3-handset {
    padding-left: 24px !important;
  }

  .mas-pl-4-handset {
    padding-left: 32px !important;
  }

  .mas-pl-5-handset {
    padding-left: 40px !important;
  }

  .mas-pr-0-handset {
    padding-right: 0 !important;
  }

  .mas-pr-1-handset {
    padding-right: 8px !important;
  }

  .mas-pr-2-handset {
    padding-right: 16px !important;
  }

  .mas-pr-3-handset {
    padding-right: 24px !important;
  }

  .mas-pr-4-handset {
    padding-right: 32px !important;
  }

  .mas-pr-5-handset {
    padding-right: 40px !important;
  }

  .mas-px-0-handset {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mas-px-1-handset {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .mas-px-2-handset {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .mas-px-3-handset {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .mas-px-4-handset {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .mas-px-5-handset {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .mas-py-0-handset {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .mas-py-1-handset {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .mas-py-2-handset {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .mas-py-3-handset {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .mas-py-4-handset {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .mas-py-5-handset {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .mas-m-0-handset {
    margin: 0 !important;
  }

  .mas-m-1-handset {
    margin: 8px !important;
  }

  .mas-m-2-handset {
    margin: 16px !important;
  }

  .mas-m-3-handset {
    margin: 24px !important;
  }

  .mas-m-4-handset {
    margin: 32px !important;
  }

  .mas-m-5-handset {
    margin: 40px !important;
  }

  .mas-mt-0-handset {
    margin-top: 0 !important;
  }

  .mas-mt-1-handset {
    margin-top: 8px !important;
  }

  .mas-mt-2-handset {
    margin-top: 16px !important;
  }

  .mas-mt-3-handset {
    margin-top: 24px !important;
  }

  .mas-mt-4-handset {
    margin-top: 32px !important;
  }

  .mas-mt-5-handset {
    margin-top: 40px !important;
  }

  .mas-mb-0-handset {
    margin-bottom: 0 !important;
  }

  .mas-mb-1-handset {
    margin-bottom: 8px !important;
  }

  .mas-mb-2-handset {
    margin-bottom: 16px !important;
  }

  .mas-mb-3-handset {
    margin-bottom: 24px !important;
  }

  .mas-mb-4-handset {
    margin-bottom: 32px !important;
  }

  .mas-mb-5-handset {
    margin-bottom: 40px !important;
  }

  .mas-ml-0-handset {
    margin-left: 0 !important;
  }

  .mas-ml-1-handset {
    margin-left: 8px !important;
  }

  .mas-ml-2-handset {
    margin-left: 16px !important;
  }

  .mas-ml-3-handset {
    margin-left: 24px !important;
  }

  .mas-ml-4-handset {
    margin-left: 32px !important;
  }

  .mas-ml-5-handset {
    margin-left: 40px !important;
  }

  .mas-mr-0-handset {
    margin-right: 0 !important;
  }

  .mas-mr-1-handset {
    margin-right: 8px !important;
  }

  .mas-mr-2-handset {
    margin-right: 16px !important;
  }

  .mas-mr-3-handset {
    margin-right: 24px !important;
  }

  .mas-mr-4-handset {
    margin-right: 32px !important;
  }

  .mas-mr-5-handset {
    margin-right: 40px !important;
  }

  .mas-mx-0-handset {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mas-mx-1-handset {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mas-mx-2-handset {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .mas-mx-3-handset {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .mas-mx-4-handset {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }

  .mas-mx-5-handset {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mas-my-0-handset {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .mas-my-1-handset {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mas-my-2-handset {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .mas-my-3-handset {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .mas-my-4-handset {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .mas-my-5-handset {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mas-mx-auto-handset {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .mas-p-default {
    padding: 16px !important;
  }

  .mas-m-default {
    margin: 16px !important;
  }

  .mas-text-left-handset {
    text-align: left !important;
  }

  .mas-text-center-handset {
    text-align: center !important;
  }

  .mas-text-right-handset {
    text-align: right !important;
  }

  .mas-text-justify-handset {
    text-align: justify !important;
  }

  .mas-w100-handset {
    width: 100% !important;
  }

  .mas-max100-handset {
    max-width: 100% !important;
  }
}

@media (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape) {
  .mas-items-start-tablet {
    align-items: start !important;
  }

  .mas-items-end-tablet {
    align-items: end !important;
  }

  .mas-items-center-tablet {
    align-items: center !important;
  }

  .mas-items-baseline-tablet {
    align-items: baseline !important;
  }

  .mas-items-stretch-tablet {
    align-items: stretch !important;
  }

  .mas-flex-row-tablet {
    flex-direction: row !important;
  }

  .mas-flex-row-reverse-tablet {
    flex-direction: row-reverse !important;
  }

  .mas-flex-col-tablet {
    flex-direction: column !important;
  }

  .mas-flex-col-reverse-tablet {
    flex-direction: column-reverse !important;
  }

  .mdx-flex-fill--tablet {
    flex: auto !important;
  }

  .mas-flex-grow-tablet {
    flex-grow: 1 !important;
  }

  .mas-flex-grow-0-tablet {
    flex-grow: 0 !important;
  }

  .mas-flex-shrink-tablet {
    flex-shrink: 1 !important;
  }

  .mas-flex-shrink-0-tablet {
    flex-shrink: 0 !important;
  }

  .mas-justify-start-tablet {
    justify-content: flex-start !important;
  }

  .mas-justify-end-tablet {
    justify-content: flex-end !important;
  }

  .mas-justify-center-tablet {
    justify-content: center !important;
  }

  .mas-justify-between-tablet {
    justify-content: space-between !important;
  }

  .mas-justify-around-tablet {
    justify-content: space-around !important;
  }

  .mas-justify-evenly-tablet {
    justify-content: space-evenly !important;
  }

  .mas-none-tablet {
    display: none !important;
  }

  .mas-inline-tablet {
    display: inline !important;
  }

  .mas-inline-block-tablet {
    display: inline-block !important;
  }

  .mas-block-tablet {
    display: block !important;
  }

  .mas-grid-tablet {
    display: grid !important;
  }

  .mas-table-tablet {
    display: table !important;
  }

  .mas-table-cell-tablet {
    display: table-cell !important;
  }

  .mas-table-row-tablet {
    display: table-row !important;
  }

  .mas-flex-tablet {
    display: flex !important;
  }

  .mas-inline-flex-tablet {
    display: inline-flex !important;
  }

  .mas-p-0-tablet {
    padding: 0 !important;
  }

  .mas-p-1-tablet {
    padding: 8px !important;
  }

  .mas-p-2-tablet {
    padding: 16px !important;
  }

  .mas-p-3-tablet {
    padding: 24px !important;
  }

  .mas-p-4-tablet {
    padding: 32px !important;
  }

  .mas-p-5-tablet {
    padding: 40px !important;
  }

  .mas-pt-0-tablet {
    padding-top: 0 !important;
  }

  .mas-pt-1-tablet {
    padding-top: 8px !important;
  }

  .mas-pt-2-tablet {
    padding-top: 16px !important;
  }

  .mas-pt-3-tablet {
    padding-top: 24px !important;
  }

  .mas-pt-4-tablet {
    padding-top: 32px !important;
  }

  .mas-pt-5-tablet {
    padding-top: 40px !important;
  }

  .mas-pb-0-tablet {
    padding-bottom: 0 !important;
  }

  .mas-pb-1-tablet {
    padding-bottom: 8px !important;
  }

  .mas-pb-2-tablet {
    padding-bottom: 16px !important;
  }

  .mas-pb-3-tablet {
    padding-bottom: 24px !important;
  }

  .mas-pb-4-tablet {
    padding-bottom: 32px !important;
  }

  .mas-pb-5-tablet {
    padding-bottom: 40px !important;
  }

  .mas-pl-0-tablet {
    padding-left: 0 !important;
  }

  .mas-pl-1-tablet {
    padding-left: 8px !important;
  }

  .mas-pl-2-tablet {
    padding-left: 16px !important;
  }

  .mas-pl-3-tablet {
    padding-left: 24px !important;
  }

  .mas-pl-4-tablet {
    padding-left: 32px !important;
  }

  .mas-pl-5-tablet {
    padding-left: 40px !important;
  }

  .mas-pr-0-tablet {
    padding-right: 0 !important;
  }

  .mas-pr-1-tablet {
    padding-right: 8px !important;
  }

  .mas-pr-2-tablet {
    padding-right: 16px !important;
  }

  .mas-pr-3-tablet {
    padding-right: 24px !important;
  }

  .mas-pr-4-tablet {
    padding-right: 32px !important;
  }

  .mas-pr-5-tablet {
    padding-right: 40px !important;
  }

  .mas-px-0-tablet {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mas-px-1-tablet {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .mas-px-2-tablet {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .mas-px-3-tablet {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .mas-px-4-tablet {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .mas-px-5-tablet {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .mas-py-0-tablet {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .mas-py-1-tablet {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .mas-py-2-tablet {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .mas-py-3-tablet {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .mas-py-4-tablet {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .mas-py-5-tablet {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .mas-m-0-tablet {
    margin: 0 !important;
  }

  .mas-m-1-tablet {
    margin: 8px !important;
  }

  .mas-m-2-tablet {
    margin: 16px !important;
  }

  .mas-m-3-tablet {
    margin: 24px !important;
  }

  .mas-m-4-tablet {
    margin: 32px !important;
  }

  .mas-m-5-tablet {
    margin: 40px !important;
  }

  .mas-mt-0-tablet {
    margin-top: 0 !important;
  }

  .mas-mt-1-tablet {
    margin-top: 8px !important;
  }

  .mas-mt-2-tablet {
    margin-top: 16px !important;
  }

  .mas-mt-3-tablet {
    margin-top: 24px !important;
  }

  .mas-mt-4-tablet {
    margin-top: 32px !important;
  }

  .mas-mt-5-tablet {
    margin-top: 40px !important;
  }

  .mas-mb-0-tablet {
    margin-bottom: 0 !important;
  }

  .mas-mb-1-tablet {
    margin-bottom: 8px !important;
  }

  .mas-mb-2-tablet {
    margin-bottom: 16px !important;
  }

  .mas-mb-3-tablet {
    margin-bottom: 24px !important;
  }

  .mas-mb-4-tablet {
    margin-bottom: 32px !important;
  }

  .mas-mb-5-tablet {
    margin-bottom: 40px !important;
  }

  .mas-ml-0-tablet {
    margin-left: 0 !important;
  }

  .mas-ml-1-tablet {
    margin-left: 8px !important;
  }

  .mas-ml-2-tablet {
    margin-left: 16px !important;
  }

  .mas-ml-3-tablet {
    margin-left: 24px !important;
  }

  .mas-ml-4-tablet {
    margin-left: 32px !important;
  }

  .mas-ml-5-tablet {
    margin-left: 40px !important;
  }

  .mas-mr-0-tablet {
    margin-right: 0 !important;
  }

  .mas-mr-1-tablet {
    margin-right: 8px !important;
  }

  .mas-mr-2-tablet {
    margin-right: 16px !important;
  }

  .mas-mr-3-tablet {
    margin-right: 24px !important;
  }

  .mas-mr-4-tablet {
    margin-right: 32px !important;
  }

  .mas-mr-5-tablet {
    margin-right: 40px !important;
  }

  .mas-mx-0-tablet {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mas-mx-1-tablet {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mas-mx-2-tablet {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .mas-mx-3-tablet {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .mas-mx-4-tablet {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }

  .mas-mx-5-tablet {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mas-my-0-tablet {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .mas-my-1-tablet {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mas-my-2-tablet {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .mas-my-3-tablet {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .mas-my-4-tablet {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .mas-my-5-tablet {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mas-mx-auto-tablet {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .mas-p-default {
    padding: 32px !important;
  }

  .mas-m-default {
    margin: 32px !important;
  }

  .mas-text-left-tablet {
    text-align: left !important;
  }

  .mas-text-center-tablet {
    text-align: center !important;
  }

  .mas-text-right-tablet {
    text-align: right !important;
  }

  .mas-text-justify-tablet {
    text-align: justify !important;
  }

  .mas-w100-tablet {
    width: 100% !important;
  }

  .mas-max100-tablet {
    max-width: 100% !important;
  }
}

@media (min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape) {
  .mas-items-start-web {
    align-items: start !important;
  }

  .mas-items-end-web {
    align-items: end !important;
  }

  .mas-items-center-web {
    align-items: center !important;
  }

  .mas-items-baseline-web {
    align-items: baseline !important;
  }

  .mas-items-stretch-web {
    align-items: stretch !important;
  }

  .mas-flex-row-web {
    flex-direction: row !important;
  }

  .mas-flex-row-reverse-web {
    flex-direction: row-reverse !important;
  }

  .mas-flex-col-web {
    flex-direction: column !important;
  }

  .mas-flex-col-reverse-web {
    flex-direction: column-reverse !important;
  }

  .mdx-flex-fill--web {
    flex: auto !important;
  }

  .mas-flex-grow-web {
    flex-grow: 1 !important;
  }

  .mas-flex-grow-0-web {
    flex-grow: 0 !important;
  }

  .mas-flex-shrink-web {
    flex-shrink: 1 !important;
  }

  .mas-flex-shrink-0-web {
    flex-shrink: 0 !important;
  }

  .mas-justify-start-web {
    justify-content: flex-start !important;
  }

  .mas-justify-end-web {
    justify-content: flex-end !important;
  }

  .mas-justify-center-web {
    justify-content: center !important;
  }

  .mas-justify-between-web {
    justify-content: space-between !important;
  }

  .mas-justify-around-web {
    justify-content: space-around !important;
  }

  .mas-justify-evenly-web {
    justify-content: space-evenly !important;
  }

  .mas-none-web {
    display: none !important;
  }

  .mas-inline-web {
    display: inline !important;
  }

  .mas-inline-block-web {
    display: inline-block !important;
  }

  .mas-block-web {
    display: block !important;
  }

  .mas-grid-web {
    display: grid !important;
  }

  .mas-table-web {
    display: table !important;
  }

  .mas-table-cell-web {
    display: table-cell !important;
  }

  .mas-table-row-web {
    display: table-row !important;
  }

  .mas-flex-web {
    display: flex !important;
  }

  .mas-inline-flex-web {
    display: inline-flex !important;
  }

  .mas-p-0-web {
    padding: 0 !important;
  }

  .mas-p-1-web {
    padding: 8px !important;
  }

  .mas-p-2-web {
    padding: 16px !important;
  }

  .mas-p-3-web {
    padding: 24px !important;
  }

  .mas-p-4-web {
    padding: 32px !important;
  }

  .mas-p-5-web {
    padding: 40px !important;
  }

  .mas-pt-0-web {
    padding-top: 0 !important;
  }

  .mas-pt-1-web {
    padding-top: 8px !important;
  }

  .mas-pt-2-web {
    padding-top: 16px !important;
  }

  .mas-pt-3-web {
    padding-top: 24px !important;
  }

  .mas-pt-4-web {
    padding-top: 32px !important;
  }

  .mas-pt-5-web {
    padding-top: 40px !important;
  }

  .mas-pb-0-web {
    padding-bottom: 0 !important;
  }

  .mas-pb-1-web {
    padding-bottom: 8px !important;
  }

  .mas-pb-2-web {
    padding-bottom: 16px !important;
  }

  .mas-pb-3-web {
    padding-bottom: 24px !important;
  }

  .mas-pb-4-web {
    padding-bottom: 32px !important;
  }

  .mas-pb-5-web {
    padding-bottom: 40px !important;
  }

  .mas-pl-0-web {
    padding-left: 0 !important;
  }

  .mas-pl-1-web {
    padding-left: 8px !important;
  }

  .mas-pl-2-web {
    padding-left: 16px !important;
  }

  .mas-pl-3-web {
    padding-left: 24px !important;
  }

  .mas-pl-4-web {
    padding-left: 32px !important;
  }

  .mas-pl-5-web {
    padding-left: 40px !important;
  }

  .mas-pr-0-web {
    padding-right: 0 !important;
  }

  .mas-pr-1-web {
    padding-right: 8px !important;
  }

  .mas-pr-2-web {
    padding-right: 16px !important;
  }

  .mas-pr-3-web {
    padding-right: 24px !important;
  }

  .mas-pr-4-web {
    padding-right: 32px !important;
  }

  .mas-pr-5-web {
    padding-right: 40px !important;
  }

  .mas-px-0-web {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .mas-px-1-web {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .mas-px-2-web {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .mas-px-3-web {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .mas-px-4-web {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .mas-px-5-web {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .mas-py-0-web {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .mas-py-1-web {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .mas-py-2-web {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  .mas-py-3-web {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }

  .mas-py-4-web {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
  }

  .mas-py-5-web {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .mas-m-0-web {
    margin: 0 !important;
  }

  .mas-m-1-web {
    margin: 8px !important;
  }

  .mas-m-2-web {
    margin: 16px !important;
  }

  .mas-m-3-web {
    margin: 24px !important;
  }

  .mas-m-4-web {
    margin: 32px !important;
  }

  .mas-m-5-web {
    margin: 40px !important;
  }

  .mas-mt-0-web {
    margin-top: 0 !important;
  }

  .mas-mt-1-web {
    margin-top: 8px !important;
  }

  .mas-mt-2-web {
    margin-top: 16px !important;
  }

  .mas-mt-3-web {
    margin-top: 24px !important;
  }

  .mas-mt-4-web {
    margin-top: 32px !important;
  }

  .mas-mt-5-web {
    margin-top: 40px !important;
  }

  .mas-mb-0-web {
    margin-bottom: 0 !important;
  }

  .mas-mb-1-web {
    margin-bottom: 8px !important;
  }

  .mas-mb-2-web {
    margin-bottom: 16px !important;
  }

  .mas-mb-3-web {
    margin-bottom: 24px !important;
  }

  .mas-mb-4-web {
    margin-bottom: 32px !important;
  }

  .mas-mb-5-web {
    margin-bottom: 40px !important;
  }

  .mas-ml-0-web {
    margin-left: 0 !important;
  }

  .mas-ml-1-web {
    margin-left: 8px !important;
  }

  .mas-ml-2-web {
    margin-left: 16px !important;
  }

  .mas-ml-3-web {
    margin-left: 24px !important;
  }

  .mas-ml-4-web {
    margin-left: 32px !important;
  }

  .mas-ml-5-web {
    margin-left: 40px !important;
  }

  .mas-mr-0-web {
    margin-right: 0 !important;
  }

  .mas-mr-1-web {
    margin-right: 8px !important;
  }

  .mas-mr-2-web {
    margin-right: 16px !important;
  }

  .mas-mr-3-web {
    margin-right: 24px !important;
  }

  .mas-mr-4-web {
    margin-right: 32px !important;
  }

  .mas-mr-5-web {
    margin-right: 40px !important;
  }

  .mas-mx-0-web {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mas-mx-1-web {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }

  .mas-mx-2-web {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }

  .mas-mx-3-web {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }

  .mas-mx-4-web {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }

  .mas-mx-5-web {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }

  .mas-my-0-web {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .mas-my-1-web {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }

  .mas-my-2-web {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }

  .mas-my-3-web {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }

  .mas-my-4-web {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }

  .mas-my-5-web {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .mas-mx-auto-web {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .mas-p-default {
    padding: 0 !important;
  }

  .mas-m-default {
    margin: 0 !important;
  }

  .mas-text-left-web {
    text-align: left !important;
  }

  .mas-text-center-web {
    text-align: center !important;
  }

  .mas-text-right-web {
    text-align: right !important;
  }

  .mas-text-justify-web {
    text-align: justify !important;
  }

  .mas-w100-web {
    width: 100% !important;
  }

  .mas-max100-web {
    max-width: 100% !important;
  }
}

.typo__h2, .typo__h3 {
  font-weight: 500;
}

.typographed-text {
  font-size: 16px;
}

.typographed-text p {
  letter-spacing: .01rem;
  margin-bottom: 16px;
  line-height: 130%;
}

body {
  color: #212529;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

@media (min-width: 1240px) {
  .page-container {
    grid-template-columns: min(45%, 700px) minmax(55%, 900px);
    display: grid;
  }

  .page-container .logo-container {
    height: 100vh;
    margin-top: 0;
  }
}

.logo-container {
  background-color: #fff8f7;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 64px;
  display: flex;
}

.logo-img {
  width: 50vw;
  max-width: 270px;
  margin-bottom: 1.5rem;
}

h1.logo-title {
  text-align: center;
  margin-bottom: .5rem;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.project-container {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
}

.project-subtitle {
  color: #90a4ae;
  font-size: 16px;
}

#split-button-img {
  width: 203px;
}

@media (max-width: 599px) {
  #split-button-img {
    width: 150px;
  }
}

/*# sourceMappingURL=index.1eb26ccf.css.map */
